/*
* 主要储存所有职位相关的信息.
*/
const jobs = [
  {
    id: 'pos1',
    name: 'Marketing Specialist',
    responsibilities: [
      'Research social media influencers and build strong relationships with them through negotiation and execution of digital campaigns and regular communication.',
      'Review data on the performance of influencers campaigns and adjusts plans or strategies to optimize them.',
      'Support the marketing team in other social media, marketing & sales tasks.',
    ],
    requirements: [
      'Have passion on social media, experience working with Internet company will be preferred.',
      'English can be working language.',
      'Familiarity with mainstream social media platforms, including but not limited to Facebook, YouTube, Instagram, Snapchat, Twitter and Google+.',
      'Detail oriented with strong written and verbal communication skills.',
      'Must be willing to work in a fast-paced start-up environment and efficient.',
      'Relevant experience of 1-3 years is an advantage.',
    ],
    countries: ['Egypt', 'Vietnam', 'Indonesia', 'Mexico', 'Thailand', 'Malaysia', 'Philippines', 'Japan', 'Other areas'],
  },
  {
    id: 'pos2',
    name: 'Operation Specialist',
    responsibilities: [
      'Find and communicate with the oursourcing companies.',
      'Recruit part-time online editors and teach them to use our editing system.',
      'Assist Chinese colleagues in completing tasks related to the project.',
    ],
    requirements: [
      'Can use Chinese or English as the working language.',
      "A bachelor's degree",
      ' Have a good command in computer operation, email correspondence, and use of Miscrosoft Office applications',
      'Sense of responsibilty and good communication skills',
      'Ability to work independently',
    ],
    countries: ['Vietnam', 'Indonesia', 'Mexico', 'Philippines', 'Other areas'],
  },
  {
    id: 'pos3',
    name: 'Marketing Intern(Graduate on 2022)',
    responsibilities: [
      'Research social media influencers and build strong relationships with them through negotiation and execution of digital campaigns and regular communication.',
      'Support the marketing team in other social media, marketing & sales tasks.',
    ],
    requirements: [
      'Have passion on social media, experience working with Internet company will be preferred.',
      'English can be working language.',
      'Familiarity with mainstream social media platforms, including but not limited to Facebook, YouTube, Instagram, Snapchat, Twitter and Google+.',
      'Can work at least 3 days every week.',
    ],
    countries: ['Egypt', 'Vietnam', 'Indonesia', 'Mexico', 'Thailand', 'Malaysia', 'Philippines', 'Japan', 'Other areas'],

  },
];

export default {
  jobs,
};
