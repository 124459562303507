<!--
* Recruitment 组件：job.vue
* 功能：主要针对单个职位的介绍，包含职位名称，职责，要求，以及区域。
-->
<template lang="pug">
.job_panel
  .job(:key="selectLang")
    p.name
      span.title {{$TT(job.name)}}
    .part_one
      p.head {{$TT('Responsibilities')}}
      ol.list(:style="showDetail ? '' : 'height: 108px'")
        li.list_item(
          v-for="(item, index) in job.responsibilities"
        ) {{$TT(item)}}
      .mask(v-show="!showDetail")
      .expand_btn(v-show="!showDetail" @click="showAll")
        p.text {{$TT('Expand details')}}
        img.arrow(src="@/assets/images/recuritment/expand_arrow.png")
    .part_two(v-show="showDetail")
      p.head {{$TT('Requirements')}}
      ol.list
        li.list_item(
          v-for="(item, index) in job.requirements"
        ) {{$TT(item)}}
    .part_three
      p.head {{$TT('Click to post')}}
      .countries_box
        country-icon.item(
          v-for="(item, index) in countries"
          :key="index"
          :country="item"
        )
  .gap
</template>
<script>
import { mapState } from 'vuex';
import data from '@/config/countries';
import countryIcon from './countryIcon.vue';

export default {
  name: 'Job',
  components: { countryIcon },
  props: ['job'],
  data() {
    return {
      showDetail: false,
      testData: {},
      countries: {},
    };
  },
  mounted() {
    this.countries = data.countries;
  },
  methods: {
    showAll() {
      this.showDetail = true;
    },
  },
  computed: {
    ...mapState(['selectLang']),
  },
}
</script>
<style lang="stylus" scoped>
.job
  font-family Gilroy
  width 966px
  margin 0 auto
  margin 56px 80px 40px 75px
  .name
    font-size 24px
    font-height 29px
    color #061C32
    /deep/ .title
      font-weight bold
    /deep/ .subtitle
      font-weight 400
  .part_one
    margin-top 32px
    padding-bottom 24px
    border-bottom 1px solid rgba(151,151,151, 0.14)
    .mask
      position absolute
      transform translateY(-108px)
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.42) 0%, #FFFFFF 75.97%);
      width 100%
      height 108px
    .expand_btn
      height 17px
      display flex
      align-items center
      justify-content center
      transform translateY(-35px)
      .text
        font-weight 600
        font-size 14px
        line-height 17px
        color #FF592B
      .arrow
        width 10px
        height 4px
        margin-left 8px
      &:hover
        cursor pointer
  .part_two
    margin-top 24px
  .part_three
    margin-top 24px
    .countries_box
      display flex
      flex-wrap wrap
      .item
        margin 10px 15px
.head
  font-weight 600
  font-size 20px
  line-height 24px
  color #B5B7B9
.list
  overflow hidden
  /deep/ .list_item
    font-size 16px
    line-height 19px
    letter-spacing 0.5px
    color #31373C
    list-style decimal inside
    margin-top 16px
    &::marker
      font-size 16px
      line-height 19px
      margin-right 13px
      color #B5B7B9
.gap
  width 100%
  height 24px
  background rgba(202,202,202, 0.2)
</style>
