<template lang="pug">
.recuritment
  nav-bar
  .recruitment_box(:key="selectLang")
    .title_panel
      .head
        span {{$TT('Global Recruitment')}}
      .desc
        span {{$TT('Marketing Specialist')}}
        span.target {{$TT('Marketing Intern')}}
        span {{$TT('Operation Specialist')}}
    .jobs_panel
      p.desc {{$TT('Start a new job')}}
      job(
        v-for="item in jobsList"
        :key="item.id"
        :job="item"
      )
  dict-footer
</template>
<script>
import { mapState } from 'vuex';
import data from '@/config/jobs';
import navBar from '@/components/pc/navBar.vue';
import dictFooter from '@/components/pc/dictFooter.vue';
import Job from './components/Job.vue';

export default {
  name: 'mediaKit',
  components: { navBar, Job, dictFooter },
  data() {
    return {
      jobsList: [],
    }
  },
  mounted() {
    this.jobsList = data.jobs
    console.log('jobs --> ', this.jobsList)
  },
  computed: {
    ...mapState(['selectLang']),
  },
}
</script>
<style lang="stylus" scoped>
.recuritment
  font-family Gilroy
  width 100%
  min-width 1300px
  height 527px
  background-image url('~@/assets/images/recuritment/background.png')
  background-size 100% 527px
  background-position top left
  .recruitment_box
    z-index 99
    position relative
    width 1300px
    padding-top 62px
    margin 0 auto
    .title_panel
      text-align center
      color #FFF
      margin-top 166px
      .head
        font-size 32px
        line-height 39pz
        text-shadow 0 2px 8px rgba(0, 0, 0, 0.1)
        .text
          font-weight 400px
          margin-left 10px
      .desc
        font-size 16px
        line-height 19px
        opacity 0.7
        margin-top 16px
        .target
          &::before
            content '|'
            margin 0 30px
            opacity 0.2
          &::after
            content '|'
            margin 0 30px
            opacity 0.2
    .jobs_panel
      width 1120px
      margin 130px auto 64px
      background #FFF
      border 1px solid #cacaca
      border-radius 20px
      .desc
        font-size 20px
        font-weight 600
        line-height 24px
        color #061c32
        margin-left 75px
        padding-top 45px
.bold
  font-weight 700
</style>
