/*
* 存储所有国家 logo 相关的数据
*/
const countries = {
  Egypt: {
    name: 'Egypt',
    icon: require('@/assets/images/recuritment/countryIcons/Egypt/icon.png'),
    background: require('@/assets/images/recuritment/countryIcons/Egypt/background.png'),
    email: 'arabic@u-dictionary.com',
  },
  Vietnam: {
    name: 'Vietnam',
    icon: require('@/assets/images/recuritment/countryIcons/Vietnam/icon.png'),
    background: require('@/assets/images/recuritment/countryIcons/Vietnam/background.png'),
    email: 'vietnam@u-dictionary.com',
  },
  Indonesia: {
    name: 'Indonesia',
    icon: require('@/assets/images/recuritment/countryIcons/Indonesia/icon.png'),
    background: require('@/assets/images/recuritment/countryIcons/Indonesia/background.png'),
    email: 'indonesia@u-dictionary.com',
  },
  Mexico: {
    name: 'Mexico',
    icon: require('@/assets/images/recuritment/countryIcons/Mexico/icon.png'),
    background: require('@/assets/images/recuritment/countryIcons/Mexico/background.png'),
    email: 'latam@u-dictionary.com',
  },
  Thailand: {
    name: 'Thailand',
    icon: require('@/assets/images/recuritment/countryIcons/Thailand/icon.png'),
    background: require('@/assets/images/recuritment/countryIcons/Thailand/background.png'),
    email: 'thailand@u-dictionary.com',
  },
  Malaysia: {
    name: 'Malaysia',
    icon: require('@/assets/images/recuritment/countryIcons/Malaysia/icon.png'),
    background: require('@/assets/images/recuritment/countryIcons/Malaysia/background.png'),
    email: 'malaysia@u-dictionary.com',
  },
  Philippines: {
    name: 'Philippines',
    icon: require('@/assets/images/recuritment/countryIcons/Philippines/icon.png'),
    background: require('@/assets/images/recuritment/countryIcons/Philippines/background.png'),
    email: 'lino@u-dictionary.com',
  },
  Japan: {
    name: 'Japan',
    icon: require('@/assets/images/recuritment/countryIcons/Japan/icon.png'),
    background: require('@/assets/images/recuritment/countryIcons/Japan/background.png'),
    email: 'cherry@u-dictionary.com',
  },
  Other: {
    name: 'Other areas',
    icon: require('@/assets/images/recuritment/countryIcons/Other/icon.png'),
    background: require('@/assets/images/recuritment/countryIcons/Other/background.png'),
    email: 'Scarlett@u-dictionary.com',
  },
}

export default {
  countries,
}
