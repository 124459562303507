<!--
* Job page 子组件：country-icon
* 主要用途： 展示不同的国家logo，以及点击后触发不同国家的投递简历的地*          址。针对不同国家，存在不同的标志。
-->
<template lang="pug">
.country_icon
  a.email(:href="'mailto:'+country.email")
  img.icon(:src="country.icon")
  p.name {{ $TT(country.name) }}
  img.background(:src="country.background")
  img.arrow(src="@/assets/images/recuritment/countryIcons/icon_arrow.png")
</template>
<script>
export default {
  name: 'countryIcon',
  props: ['country'],
}
</script>
<style lang="stylus" scoped>
.country_icon
  height 44px
  width 146px
  background #061C32
  border-radius 10px
  position relative
  .background
    position absolute
    width 146px
    top 0
    left 0
    border-radius 10px
    opacity 0.8
  .icon
    position absolute
    width 26px
    left 9px
    top 9px
  .name
    position absolute
    text-align right
    font-size 14px
    line-height 19px
    letter-spacing 0.4px
    color #FFF
    left 45px
    top 13px
  .arrow
    position absolute
    width 5px
    left 130px
    top 17px
  .email
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    z-index 100
</style>
